<template>
  <v-stepper
    v-model="step"
    class="elevation-0 pa-0"
    style="background-color: transparent"
  >
    <v-stepper-content step="1" class="pa-0 pb-2">
      <h3 class="white--text">شماره موبایلتو وارد کن:</h3>
      <v-form ref="form" @submit.prevent="prelogin">
        <v-text-field
          v-model="cellphone"
          label="شماره موبایل"
          outlined
          solo
          required
          flat
          class=" mt-4 centered-input moholand-rounded ma-0 pa-0"
          :rules="[rules.required, rules.cellphone]"
          validate-on-blur
          hide-details="auto"
          background-color="white"
          color="black"
        />
        <v-btn
          class="my-4 white--text moholand-rounded font-weight-bold"
          elevation="0"
          color="orange"
          block
          x-large
          type="submit"
          :loading="loading"
        >
          ورود
        </v-btn>
        <small class="white--text">
          در صورت هر مشکلی به پشتیبانی تلگرام موهولند به آدرس : moholand_admin
          پیام بفرستید.
        </small>
      </v-form>
    </v-stepper-content>
    <v-stepper-content step="2" class="pa-0">
      <v-form ref="form2">
        <v-row>
          <v-col cols="12">
            <div style="display: flex; flex-direction: row;">
              <OtpInput
                v-model="otp"
                ref="otpInput"
                class="white--text"
                separator=""
                :num-inputs="6"
                :valid="otpValid"
                :should-auto-focus="true"
                :is-input-num="true"
                @onComplete="handleOnComplete"
              />
            </div>
          </v-col>
          <!-- <v-col cols="12">
            <vue-countdown
              :time=""
              :interval="1000"
              v-slot="{ days, hours, minutes, seconds }"
              class="mx-auto"
            >
              <div class="d-flex justify-center align-center ">
                <div>
                  {{
                    (days > 0 && days + ":") +
                      hours +
                      ":" +
                      minutes +
                      ":" +
                      seconds
                  }}
                </div>
                <v-icon class="mr-1">
                  mdi-clock-outline
                </v-icon>
              </div>
            </vue-countdown>
          </v-col> -->
          <v-col cols="12">
            <v-btn
              @click="login"
              class="my-1 white--text moholand-rounded font-weight-bold"
              elevation="0"
              color="orange"
              block
              x-large
              :loading="loading"
            >
              ورود
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              @click="step -= 1"
              class="my-1 white--text moholand-rounded font-weight-bold"
              elevation="0"
              color="orange"
              block
              x-large
              :disabled="loading"
            >
              بازگشت به ورود شماره موبایل
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import {
  Constants,
  persianNumToEnglish,
} from "../../constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "../../constants/MyAxios";
import OtpInput from "../../components/Otp";

export default {
  components: {
    OtpInput,
  },
  data: () => ({
    loading: false,
    cellphone: "",
    password: "",
    rules: Constants.rules,
    step: 1,
    otp: "",
    otpValid: true,
    newPassword: "",
    newPasswordRepeat: "",
  }),
  methods: {
    handleOnComplete(value) {
      this.otpValid = true;
      this.otp = value;
      this.login();
    },
    prelogin() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        this._prelogin();
      }
    },
    _prelogin() {
      Axios.post(Constants.mainURL + "/user/pre/login", {
        cellphone: persianNumToEnglish(this.cellphone),
      })
        .then((response) => {
          this.loading = false;
          if (response.data?.msg == "auth.user_not_found") {
            this.$root.$emit("toast", {
              text:
                "کاربری با این شماره تلفن یافت نشد. لطفا ابتدا ثبت نام کنید.",
            });
          } else this.step += 1;
          console.log(response.data);
        })
        .catch((error) => {
          if (error.response.status == 423) {
            console.log(error, error.response);
            // this.step = 2;
            this.loading = false;
          } else {
            console.log(error, error.response);
            this.loading = false;
            this.$root.$emit("toast", {
              text: error.response.data.msg || error,
            });
          }
        });
    },
    login() {
      if (this.otpValid) {
        if (!this.loading) this.loading = true;
        let data = new FormData();
        data.append('cellphone',persianNumToEnglish(this.cellphone))
        data.append('code',persianNumToEnglish(this.otp.trim()))
        Axios.post(Constants.mainURL + "/user/login", data)
          .then((response) => {
            console.log(response.data);

            let role = response.data.data["roles"][0];

            MyAxios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.data[0].access_token;

            localStorage.setItem(
              "moholandtoken",
              JSON.stringify(response.data.data[0])
            );

            this.$store.commit("setLoginData", {
              token: response.data.data[0],
              userData: {
                ...response.data.data[1],
                roleName: role,
              },
            });
            // this.$store.commit("setStorageId", null);
            // this.$store.commit("setToken", response.data.data[0]);
            // this.$store.commit("setUserData", {
            //   ...response.data.data[1],
            //   roleName: role,
            // });

            console.log({
              ...response.data.data[1],
              // mainID: response.data.data["role"].id,
              roleName: role,
            });

            console.log("cart", this.$store.state.cart);

            this.$root.$emit(
              "assignCartToUser",
              response.data.data[1].id,
              (status) => {
                console.log("assignCartToUser", status);
                if (status == "error")
                  this.$store.commit("setCart", {
                    id: null,
                    products: [],
                    total_price: 0,
                  });

                if (this.$route.query.checkout)
                  this.$router.replace("checkout");
                else this.$router.replace("dashboard");
              }
            );
          })
          .catch((error) => {
            console.log(error, error.response);
            this.loading = false;
            if (error?.response?.data?.data == "auth.wrong")
              this.$root.$emit("toast", { text: "کد وارد شده اشتباه است." });
            else this.$root.$emit("toast", { text: error.response.data });
          });
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.newPassword === this.newPasswordRepeat ||
        "تکرار رمز عبور با رمز عبور جدید یکسان نیست."
      );
    },
  },
};
</script>

<style scoped>
.ltr {
  direction: ltr;
}
.centered-input >>> input {
  text-align: center;
}
</style>
