<template>
  <v-container class="container d-flex justify-center pa-0" fluid>
    <MySnackbar v-model="alert" type="error" :text="alertText" />
    <v-main
      class="content left-bg white--text"
      :style="
        'background-image: url(' +
          ($store.state.settings.login_media
            ? $store.state.settings.login_media.url
            : require('../../assets/login-bg.jpg')) +
          ');'
      "
    >
      <v-row
        class="height-100 text-center"
        align="center"
        justify="center"
        v-if="isTokenAvailable == false"
      >
        <v-col cols="11" sm="6" md="3" v-if="forgetPassword">
          <h4>فراموشی رمز عبور</h4>
          <ForgetPassword
            @showAlert="showAlert"
            @onBack="forgetPassword = false"
          />
        </v-col>
        <v-col cols="11" sm="6" md="3" v-else>
          <v-avatar tile size="150" class="pa-4 mb-4">
            <v-img :src="require('@/assets/img/box-logo-FA.png')" contain />
          </v-avatar>
          <LoginForm @showAlert="showAlert" />
          <!-- <v-btn
                        class="my-4"
                        link
                        text
                        outlined
                        color="#727272dd"
                        block
                        @click="forgetPassword = true"
                    >
                        رمز عبورتان را فراموش کرده اید؟
                    </v-btn> -->
          <div class="d-flex align-center">
            <v-divider class="white" />
            <p class="ma-auto">
              هنوز ثبت نام نکردی؟
            </p>
            <v-divider class="white" />
          </div>
          <v-btn
            class="my-4 orange--text font-weight-bold moholand-rounded"
            color="white"
            elevation="0"
            block
            x-large
            :to="'/register' + ($route.query.checkout ? '?=checkout=true' : '')"
          >
            عضویت در سایت
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "../../constants/MyAxios";

import LoginForm from "./LoginForm";
import ForgetPassword from "./ForgetPassword";
import MySnackbar from "@/components/MySnackbar";

export default {
  name: "Login",
  data: () => ({
    isTokenAvailable: false,
    alert: false,
    alertText: false,
    forgetPassword: false,
  }),
  components: {
    LoginForm,
    ForgetPassword,
    MySnackbar,
  },
  // mounted() {
  //     let storageToken = localStorage.getItem("moholandtoken");
  //     if (storageToken) {
  //         this.isTokenAvailable = true;
  //         this.loginWithToken(JSON.parse(storageToken));
  //     }
  // },
  methods: {
    loginWithToken(Token) {
      console.log("moholandtoken", Token.access_token);
      Axios.get(Constants.mainURL + "/user/info", {
        headers: {
          Authorization: "Bearer " + Token.access_token,
        },
      })
        .then((response) => {
          console.log(
            response.data.role.id,
            response.data,
            response.data.roles[0].name
          );

          MyAxios.defaults.headers.common["Authorization"] =
            "Bearer " + Token.access_token;

          this.$store.commit("setToken", Token);
          this.$store.commit("setUserData", {
            ...response.data.user,
            mainID: response.data.role.id,
            roleName: response.data.roles[0].name,
          });
          this.$store.commit("setClubData", response.data.club);
          this.$store.commit("setPermissions", response.data.permissions);

          console.log(this.$route.query)

          if (this.$route.query.checkout) this.$router.replace("checkout");
          else this.$router.replace("dashboard");
        })
        .catch((error) => {
          console.log(error, error.response.msg);
          this.showAlert(error.response.msg);
          this.isTokenAvailable = false;
        });
    },
    showAlert(txt) {
      this.alertText = txt;
      this.alert = true;
    },
  },
};
</script>

<style scoped>
.left-bg {
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-size: cover;
}
.content {
}
.logo {
}
.height-100 {
  height: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
}
/*.absoluteFill {*/
/*	background-size: contain;*/
/*	background-repeat: repeat;*/
/*	position: absolute;*/
/*	top: 0;*/
/*	bottom: 0;*/
/*	right: 0;*/
/*	left: 0;*/
/*	z-index: 0;*/
/*}*/
.ltr {
  direction: ltr;
}
</style>
