<template>
	<v-stepper v-model="step" class="elevation-0 pa-0">
		<MySnackbar
			v-model="alert.show"
			:type="alert.type"
			:text="alert.text"
		/>
		<v-stepper-content step="1" class="pa-0">
			<v-form ref="form1">
				<v-row>
					<v-col cols="12">
						<v-text-field
							v-model="phone"
							class="mt-2"
							label="شماره تلفن همراه"
							hint="نمونه: 09123456789"
							outlined
							dense
							:rules="[rules.required, rules.cellphone]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-btn
							color="success"
							@click="PostPhone"
							:loading="loading"
							block
						>
							ارسال کد به شماره تلفن
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn
							link
							block
							color="primary"
							outlined
							@click="$emit('onBack')"
						>
							بازگشت
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-stepper-content>
		<v-stepper-content step="2">
			<v-form ref="form2">
				<v-row>
					<v-col cols="12">
						<div style="display: flex; flex-direction: row;">
							<OtpInput
								v-model="otp"
								ref="otpInput"
								separator=""
								:num-inputs="6"
								:valid="otpValid"
								:should-auto-focus="true"
								:is-input-num="true"
								:disabled="disabled"
								@onComplete="handleOnComplete"
							/>
						</div>
					</v-col>
					<v-col cols="12">
						<v-text-field
							v-model="password"
							:rules="[rules.required, rules.min]"
							label="رمز عبور جدید"
							outlined
							dense
							:append-icon="
								password_show ? 'mdi-eye' : 'mdi-eye-off'
							"
							:type="password_show ? 'text' : 'password'"
							:disabled="disabled"
							@click:append="password_show = !password_show"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
							v-model="passwordRepeat"
							:rules="[
								rules.required,
								rules.min,
								passwordConfirmationRule,
							]"
							label="تکرار رمز عبور جدید"
							outlined
							dense
							:append-icon="
								password_show2 ? 'mdi-eye' : 'mdi-eye-off'
							"
							:type="password_show2 ? 'text' : 'password'"
							:disabled="disabled"
							@click:append="password_show2 = !password_show2"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-btn
							color="success"
							@click="PostNewPassword"
							block
							:loading="loading"
							:disabled="disabled"
						>
							تغییر رمز عبور
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn
							@click="step -= 1"
							block
							color="primary"
							outlined
							:disabled="disabled"
						>
							بازگشت به ورود تلفن همراه
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn
							@click="$emit('onBack')"
							block
							color="primary"
							v-if="disabled"
						>
							بازگشت به صفحه ورود به سیستم
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-stepper-content>
	</v-stepper>
</template>

<script>
import { Constants, getErrorText } from "../../constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "../../constants/MyAxios";
import OtpInput from "../../components/Otp";
import MySnackbar from "@/components/MySnackbar";

export default {
	components: {
		OtpInput,
		MySnackbar,
	},
	data: () => ({
		loading: false,
		step: 1,
		phone: "",
		otp: "",
		password: "",
		passwordRepeat: "",
		password_show: false,
		password_show2: false,
		rules: Constants.rules,
		otpValid: true,
		disabled: false,
		alert: {
			type: "success",
			text: "",
			show: false,
		},
	}),
	methods: {
		handleOnComplete(value) {
			this.otpValid = true;
		},
		PostPhone() {
			if (this.$refs["form1"].validate()) {
				if (!this.loading) this.loading = true;

				Axios.post(Constants.mainURL + "/user/pre/forgot", {
					cellphone: this.phone,
				})
					.then((response) => {
						console.log(response.data);

						this.loading = false;
						this.step = this.step + 1;
					})
					.catch((error) => {
						console.log(error, error.response);
						this.loading = false;
						this.alert = {
							show: true,
							text: getErrorText(error),
							type: "error",
						};
					});
			} else
				this.alert = {
					show: true,
					text: "لطفا تمامی فیلد ها را به درستی پر نمایید.",
					type: "error",
				};
		},
		PostNewPassword() {
			if (this.$refs["form2"].validate()) {
				if (!this.loading) this.loading = true;

				Axios.post(Constants.mainURL + "/users/forgot", {
					cellphone: this.phone,
					code: this.otp,
					newPassword: this.password,
					newPasswordConfirmation: this.passwordRepeat,
				})
					.then((response) => {
						console.log(response.data);
						this.loading = false;
						this.disabled = true;
						this.alert = {
							show: true,
							text:
								"رمز شما با موفقیت تغییر کرد. با رمز جدید میتوانید به سیستم وارد شوید.",
							type: "success",
						};
						this.$emit("onBack");
					})
					.catch((error) => {
						console.log(error, error.response);
						this.loading = false;
						this.alert = {
							show: true,
							text: getErrorText(error),
							type: "error",
						};
					});
			}
		},
	},
	computed: {
		passwordConfirmationRule() {
			return (
				this.password === this.passwordRepeat ||
				"تکرار رمز عبور با رمز عبور جدید یکسان نیست."
			);
		},
	},
};
</script>

<style scoped>
.ltr {
	direction: ltr;
}
.otp-input {
	max-width: 20px;
	height: 20px;
	padding: 1px;
	margin: 0 5px;
	font-size: 20px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	text-align: center;
	background-color: red;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
