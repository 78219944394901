<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span>کد تاییدیه پیامکی</span>
      </v-col>
      <v-col cols="12">
        <div class="d-flex ltr">
          <vie-otp-input
            :inputClasses="valid ? 'otp-input' : 'otp-input-error'"
            :numInputs="numInputs"
            :separator="separator"
            :shouldAutoFocus="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
          <p v-if="!valid" class="error-text mr-8">این فیلد اجباری است.</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VieOtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "Otp",
  components: {
    "vie-otp-input": VieOtpInput,
  },
  methods: {
    handleOnComplete(value) {
      this.$emit("onComplete", value);
    },
    handleOnChange(value) {
      this.$emit("input", value);
    },
  },
  props: {
    numInputs: {
      default: 4,
    },
    separator: {
      type: String,
      default: "**",
    },
    inputClasses: {
      type: String,
    },
    isInputNum: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
/*.otp-parent {*/
/*	width: 80%;*/
/*	text-align: center;*/
/*	justify-content: center;*/
/*	margin: auto auto;*/
/*}*/
.otp-input {
  width: 95%;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  background: white;
  text-align: center;
}
.otp-input-error {
  width: 50%;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid red !important;
}
.ltr {
  direction: ltr;
}
.error-text {
  text-align: right;
  color: red;
  direction: rtl;
  font-size: 12px;
}
</style>
